import React, { useEffect } from "react";

const OutBrainAdslot1 = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.outbrain.com/outbrain.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="OUTBRAIN"
      data-ob-contenturl="DROP_PERMALINK_HERE"
      data-widget-id="AR_1"
      data-ob-installation-key="DATAB1N0Q28EBEG3G5HMPDAG2"
    ></div>
  );
};

export default OutBrainAdslot1;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BackToTop from "./backToTop";
import Slider from "react-slick";
import NoFoundData from "./Common/noFoundData";
import decrypt from "./Common/DecryptData";
import LeftSideAd from "./ads/LeftSideAd";
import RightSideAd from "./ads/RightSideAd";
import OutBrainAdRightSide from "./ads/OutBrainAdRightSide";

const SingleViewIdea = () => {
  const location = useLocation();
  const [viewData, setViewData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const formattedTitle = location.pathname.split("/").pop().replace(/-/g, " ");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1740);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
  };

  useEffect(() => {
    const fetchSingleIdea = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${Base_Url}api/idea/${formattedTitle}`);
        if (res?.data) {
          const { iv, end } = res.data;

          const decryptedResponse = decrypt(iv, end);

          const parsedData = JSON.parse(decryptedResponse);

          setSingleData(parsedData?.data);
        }
      } catch {
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };
    fetchSingleIdea();
  }, [formattedTitle]);

  // Fetch recent ideas
  useEffect(() => {
    const fetchRecentIdeas = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/getidea?limit=50&page=1`
        );
        if (response?.data) {
          // Extract the IV and encrypted data
          const { iv, end } = response.data;

          // Decrypt the data
          const decryptedResponse = decrypt(iv, end);

          // Parse the decrypted response
          const parsedData = JSON.parse(decryptedResponse);

          // Filter out ideas that match the current topic
          const filteredViewData = parsedData?.data.filter(
            (idea) => idea.topic !== formattedTitle
          );

          // Set the filtered data
          setViewData(filteredViewData);
        }
      } catch (error) {
        console.error("Failed to fetch ideas:", error);
      }
    };
    fetchRecentIdeas();
  }, [formattedTitle]);

  // Render component
  return (
    <>
      {isDesktop && (
        <>
          {/* <LeftSideAd /> */}
          <OutBrainAdRightSide position={"fixed"} />
        </>
      )}
      {!notFound ? (
        loading ? (
          <div className="flex justify-center items-center md:min-h-[80vh] min-h-[50vh]">
            <div className="loader2 my-5"></div>
          </div>
        ) : (
          <div className="container max-w-[1450px] mx-auto px-4 mt-12">
            <h1 className="text-center md:text-[30px] text-[20px] text-[#000] font-semibold mb-6">
              {singleData.topic}
            </h1>
            <div className="flex gap-7">
              <div className="lg:w-[70%] w-full mx-auto">
                <div>
                  {singleData.image_url?.length ? (
                    singleData.image_url.length === 1 ? (
                      <div className="lg:h-[500px] h-full">
                        <image
                          src={singleData.image_url[0]}
                          alt="Single image"
                          className="h-full object-cover w-full"
                        />
                      </div>
                    ) : (
                      <Slider {...sliderSettings} className="h-full">
                        {singleData.image_url.map((image, index) => (
                          <div key={index} className="lg:h-[500px] h-full">
                            <img
                              src={image}
                              alt={`Slide ${index + 1}`}
                              className="h-full object-cover w-full"
                            />
                          </div>
                        ))}
                      </Slider>
                    )
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
                <div className="flex justify-center mt-5">
                  <p className="text-justify md:text-[18px] text-[16px]">
                    {singleData.description}
                  </p>
                </div>
              </div>
              <div className="lg:w-[30%] hidden lg:block">
                <div className="border border-[#cacaca] px-6 py-6">
                  <h3 className="font-semibold text-[24px] border-b border-[#000]">
                    Recent Ideas
                  </h3>
                  {viewData.map((idea, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        (window.location.href = `/financial-idea/${idea.topic.replace(
                          /\s+/g,
                          "-"
                        )}`)
                      }
                      className="grid grid-cols-3 mt-6 gap-4 cursor-pointer"
                    >
                      <div className="col-span-1">
                        <img
                          src={idea.image_url[0]}
                          alt=""
                          className="h-24 w-full object-cover"
                        />
                      </div>
                      <div className="col-span-2">
                        <h4 className="line-clamp-3 tracking-[0.3px] font-semibold hover:text-blue-800 text-[16px]">
                          {idea.topic}
                        </h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <NoFoundData title="Oops... No Financial Idea found!" />
      )}
      <BackToTop />
    </>
  );
};

export default SingleViewIdea;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import { useNavigate } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import BetweenContent from "../Components/ads/BetweenContent";
import Blog from "../Components/Common/blog";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import decrypt from "../Components/Common/DecryptData";
import OutBrainAdRightSide from "../Components/ads/OutBrainAdRightSide";
const Modal = lazy(() => import("../Components/Modal"));

const BlogPage = ({ setBlogLoading, blogLoading }) => {
  const recentBlogRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const limit = 9;
  const [blogData, setBlogData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1740);
  const [isFetching, setIsFetching] = useState(false);
  const isFirstLoad = useRef(true);

  const memoizedApiUrl = useMemo(() => {
    return `${Base_Url}api/get-blog?limit=${limit}&page=${page}`;
  }, [page, limit]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBlogData = async () => {
      if (isFirstLoad.current && page === 0 && blogData.length > 0) return;

      setBlogLoading(true);
      setIsFetching(true);

      try {
        const response = await axios.get(memoizedApiUrl);
        const { iv, end } = response.data;

        const decryptedResponse = decrypt(iv, end);
        const parsedData = JSON.parse(decryptedResponse);

        const newBlogs = parsedData?.data || [];
        const existingBlogIds = new Set(blogData.map((blog) => blog.id));

        const uniqueBlogs = newBlogs.filter(
          (blog) => !existingBlogIds.has(blog.id)
        );

        setBlogData((prevData) => [...prevData, ...uniqueBlogs]);
        setPageCount(parsedData?.totalPage);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      } finally {
        setBlogLoading(false);
        setIsFetching(false);
      }
    };

    fetchBlogData();

    isFirstLoad.current = false;
  }, [memoizedApiUrl]);

  useEffect(() => {
    const handleReturn = () => {
      const isReturning = localStorage.getItem("returningFromBlog");
      if (isReturning === "true") {
        localStorage.setItem("returningFromBlog", "false");
        setTimeout(() => {
          setIsModalOpen(true);
        }, 1500);
      }
    };
    handleReturn();
  }, [navigate]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLoadMore = () => {
    if (page < pageCount - 1) {
      setPage((prevPage) => prevPage + 1);
    }
    if (window.RediAds) {
      window.RediAds.refreshAds();
    }
    if (window.googletag && window.googletag.pubads) {
      try {
        window.googletag.pubads().refresh();
      } catch (error) {
        console.error("Failed to refresh ads:", error);
      }
    }
  };

  return (
    <>
      {isDesktop && (
        <>
          {/* <LeftSideAd /> */}
          <OutBrainAdRightSide position={"fixed"} />
        </>
      )}
      <div className="container max-w-7xl mx-auto px-4">
        <div className="mx-auto pt-[3rem] mb-[20px] max-[768px]:pt-[2rem]">
          <div className="flex justify-center text-center">
            <div ref={recentBlogRef} className="w-[65%] max-[1024px]:w-[90%]">
              <h1
                className="text-[#000] font-bold md:text-[40px] text-[30px] mb-0"
                style={{ fontFamily: "Playfair Display" }}
              >
                Recent Blog Post
              </h1>
            </div>
          </div>
          <div className="blog-div mt-[0.5rem] max-[768px]:mt-0 min-h-screen">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 lg:px-0">
              {blogData?.map((blog, index) => (
                <React.Fragment key={blog.id}>
                  {/* Use blog.id for key */}
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-4 h-full"
                    onClick={() => {
                      let clickCount = localStorage.getItem("clickCount") || 0;
                      let click = Number(clickCount);
                      localStorage.setItem("clickCount", click + 1);
                    }}
                  >
                    <Blog data={blog} />
                  </div>
                  {/* {index % 6 === 5 && (
                    <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                      <BetweenContent />
                    </div>
                  )} */}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {isFetching && (
          <div className="flex items-center justify-center my-3 mt-10 py-4 px-16">
            <svg
              className="animate-spin h-5 w-5 text-[#F06939] mr-3 "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2s-.9 2-2 2H6c-1.1 0-2-.9-2-2z"
              ></path>
            </svg>
            <span className="text-lg font-bold">Loading more blogs...</span>
          </div>
        )}
        {!blogLoading && blogData?.length > 0 && (
          <div className="flex flex-col items-center my-3 mt-10">
            {page < pageCount - 1 && (
              <button
                onClick={handleLoadMore}
                className="py-4 px-16 bg-[#9E2F0F] font-semibold text-white rounded-md"
              >
                Load More
              </button>
            )}
          </div>
        )}
        <Modal
          allData={[...blogData].reverse()}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
        <BackToTop />
      </div>
    </>
  );
};

export default BlogPage;

import React, { useEffect, useState, memo } from "react";
import Skeleton from "react-loading-skeleton";
import Card from "./Common/Card";
import LongAd from "./ads/LongAd";
import Base_Url from "../api/baseUrl";
import decrypt from "./Common/DecryptData";
import axios from "axios";

const TopEarner = memo(() => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch and decrypt blogs on mount
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${Base_Url}api/top-earners`);
        const { end, iv } = response?.data;
        setBlogs(JSON.parse(decrypt(iv, end)));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or decrypting blogs:", error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      <h2
        className="py-3 text-[40px] border-t border-b border-[#C7CACC] text-center sm:mt-16 mt-8 sm:mb-12 mb-5 font-semibold"
        style={{
          fontFamily: "Playfair Display",
          fontSize: "clamp(1.5rem, 4vw, 3rem)",
        }}
      >
        The Top Earners
      </h2>
      {/* <LongAd /> */}

      {/* Main Image Section */}
      <div
        className="grid sm:grid-cols-2 items-center md:gap-10 gap-5 cursor-pointer"
        onClick={() => {
          const formattedTitle = blogs[0]?.title;
          window.location.href = `/top-earner/${formattedTitle}`;
        }}
      >
        <div className="md:pe-5 sm:h-full h-[300px]">
          {loading ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <img
              src={blogs[0]?.image}
              loading="lazy"
              alt="top earner"
              className="h-full w-full object-cover object-top"
            />
          )}
        </div>
        <div className="md:flex items-center min-[1500px]:w-[80%] cursor-pointer">
          <div>
            {loading ? (
              <>
                <Skeleton height={40} width="70%" />
                <Skeleton height={20} width="90%" className="my-3" count={2} />
                <Skeleton height={20} width="40%" />
              </>
            ) : (
              <>
                <h2
                  className="font-semibold"
                  style={{
                    fontFamily: "Playfair Display",
                    fontSize: "clamp(1.6rem, 4vw, 2.5rem)",
                  }}
                >
                  {blogs[0]?.title}
                </h2>
                {/* <p
                  className="md:mt-5 mt-3 lg:text-[18px] text-[15px]"
                  dangerouslySetInnerHTML={{
                    __html: blogs[0]?.short_description,
                  }}
                ></p> */}
                <div className="flex mt-2 gap-1">
                  {blogs[0]?.sourceBy && (
                    <p className="text-[14px] text-gray-500">
                      {blogs[0]?.sourceBy + " | "}
                    </p>
                  )}
                  {blogs[0]?.created_at && (
                    <p className="text-[14px] text-gray-500">
                      {new Date(blogs[0]?.created_at).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Other Earners Section */}
      <div className="mt-7">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
          {loading
            ? Array(3)
                .fill(null)
                .map((_, index) => (
                  <div key={index}>
                    <Skeleton height={200} />
                    <Skeleton height={30} width="80%" className="mt-2" />
                    <Skeleton height={20} width="60%" />
                  </div>
                ))
            : blogs.slice(1).map((earner, index) => {
                const formattedTitle = earner?.title;
                return (
                  <Card
                    key={index}
                    data={earner}
                    formattedTitle={formattedTitle}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
});

export default TopEarner;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import ReactPaginate from "react-paginate";
import Base_Url from "../api/baseUrl";
import axios from "axios";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import ParallaxCode from "../Components/ads/ParallaxCode";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const CelebrityDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [blogDetailsData, setBlogDetailsData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const querySlug = location.pathname.split("/");
  const firstSegment = querySlug[2];
  const secondSegment = querySlug[3];
  const formattedTitle = secondSegment.replace(/-/g, " ");
  const formattedTitle2 = firstSegment.replace(/-/g, " ");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1740);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);
  
  const filteredBlogs = useMemo(() => {
    return blogDetailsData?.blogDetails || [];
  }, [blogDetailsData]);

  useEffect(() => {
    const getAllItemsById = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/celebrity/${formattedTitle2}`
        );

        // Decrypt the response data
        const { iv, end } = response.data; // Get IV and encrypted data from the response
        const decryptedData = decrypt(iv, end); // Decrypt the data

        const parsedData = JSON.parse(decryptedData);
        setBlogDetailsData(parsedData?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getAllItemsById();
  }, [formattedTitle2]);

  useEffect(() => {
    const getDetailsById = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/celebrity-blog/${formattedTitle}`
        );
        const { iv, end } = response.data; // Get IV and encrypted data from the response
        const decryptedData = decrypt(iv, end); // Decrypt the data

        const parsedData = JSON.parse(decryptedData);
        setData(parsedData?.data);
      } catch (error) {
        console.error("Error fetching celebrity data:", error);
      } finally {
        setLoading(false);
      }
    };
    getDetailsById();
  }, [formattedTitle]);

  useEffect(() => {
    if (blogDetailsData && formattedTitle) {
      const pageIndex = filteredBlogs.findIndex(
        (x) => x.name === formattedTitle
      );
      if (pageIndex !== -1) {
        setCurrentPage(pageIndex);
      }
    }
  }, [formattedTitle, blogDetailsData, filteredBlogs]);

  const pageCount = useMemo(() => {
    return Math.ceil(filteredBlogs.length / 1); // Adjust based on your items per page
  }, [filteredBlogs]);

  const handlePageClick = useCallback(
    ({ selected }) => {
      setCurrentPage(selected);
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (selected < filteredBlogs?.length) {
        const selectedBlogTitle = filteredBlogs[selected]?.name;
        if (selectedBlogTitle) {
          const newSubId = selectedBlogTitle.replace(/\s+/g, "-");
          navigate(`/celebrities-details/${firstSegment}/${newSubId}`);
        }
      }
    },
    [filteredBlogs, firstSegment, navigate]
  );

  return (
    <>
      {/* {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )} */}
      {loading ? (
        <div className="flex justify-center items-center md:min-h-screen min-h-[60vh]">
          <div className="loader2 my-5"></div>
        </div>
      ) : (
        <div className="px-5">
          <div className="container max-w-7xl mx-auto pt-[3rem] mb-[20px] max-[768px]:pt-[2rem]">
            <div className="flex border-2 p-2 rounded-lg flex-col md:flex-row">
              <img
                src={data?.image}
                alt=""
                width={400}
                height={500}
                className="object-cover object-top"
                loading="lazy"
              />
              <div className="flex justify-center flex-col md:px-[20px] px-[10px] md:gap-4 my-4 lg:my-0">
                <h1 className="text-[#000] text-5xl leading-snug font-semibold lg:text-[42px] text-[34px]">
                  {data?.name}
                </h1>
                <p className="text-gray-600 text-lg">{data?.work}</p>
                <h1 className="text-[#000] text-5xl leading-snug font-semibold lg:text-[42px] text-[34px]">
                  {data?.net_worth}
                </h1>
                <div
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                ></div>
              </div>
            </div>
            {/* <BelowFeaturedImages /> */}
            <div className="flex lg:gap-4 flex-col lg:flex-row">
              <div className="my-5 p-4 border-2 lg:w-[50%]">
                <h2 className="text-xl font-semibold pb-4">Other Details</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: data?.other_details }}
                ></div>
              </div>

              <div className="my-5 p-4 border-2 lg:w-[50%]">
                <h2 className="text-xl font-semibold pb-6">Personal Stats</h2>
                <div className="flex flex-col gap-6">
                  <div className="flex gap-4 border-b-2">
                    <p className="font-semibold">Age</p>
                    <p>{data?.age}</p>
                  </div>
                  <div className="flex gap-4 border-b-2">
                    <p className="font-semibold">Source of Wealth</p>
                    <p>{data?.source_of_wealth}</p>
                  </div>
                  <div className="flex gap-4 border-b-2">
                    <p className="font-semibold">Residence</p>
                    <p>{data?.residence}</p>
                  </div>
                  <div className="flex gap-4 border-b-2">
                    <p className="font-semibold">Citizenship</p>
                    <p>{data?.citizenship}</p>
                  </div>
                  <div className="flex gap-4 border-b-2">
                    <p className="font-semibold">Marital Status</p>
                    <p>{data?.marital_status}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <ParallaxCode /> */}
            <div className="my-5 p-4 border-2">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination flex justify-center mt-4"}
                activeClassName={"active"}
                pageClassName={"page-item px-2"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                forcePage={currentPage}
              />
            </div>
          </div>
          <BackToTop />
        </div>
      )}
    </>
  );
};

export default CelebrityDetails;

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import BetweenContent from "../Components/ads/BetweenContent";
import EndOfArticle from "../Components/ads/EndOfArticle";
import ParallaxCode from "../Components/ads/ParallaxCode";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const MillionaireShopping = () => {
  const location = useLocation();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ")
  const [allShoppingData, setAllShoppingData] = useState([]);
  const refs = useRef([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1740);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);
  
  useEffect(() => {
    const getAllShoppingData = async () => {
      try {
        const response = await axios.get(`${Base_Url}api/life-style/shopping`);
  
        const { iv, end } = response.data;
  
        const decryptedDataString = decrypt(iv, end);
        const decryptedData = JSON.parse(decryptedDataString);
  
        if (decryptedData) {
          const shopping = decryptedData.data.filter(
            (x) => x?.type === "Shopping"
          );
          setAllShoppingData(shopping);
        }
      } catch (error) {
        console.log("Error fetching shopping data:", error);
      }
    };
  
    getAllShoppingData();
  }, [formattedTitle]);
  useEffect(() => {
    if (formattedTitle && allShoppingData?.length > 0) {
      const matchedIndex = allShoppingData.findIndex(
        (item) => item.title === formattedTitle
      );
  
      if (matchedIndex !== -1 && refs.current[matchedIndex]) {
        refs.current[matchedIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [formattedTitle, allShoppingData]);

  const adsArray = [
    // <BelowFeaturedImages />,
    // <BetweenContent />,
    // <EndOfArticle />,
    // <ParallaxCode />,
  ];

  return (
    <div>
      {/* {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )} */}
      <div className="bg-white py-5 px-3">
        <div className="container max-w-7xl mx-auto">
          {allShoppingData?.map((item, index) => {
            // const adComponent = adsArray[index % adsArray.length];
            return (
              <div ref={(el) => (refs.current[index] = el)} key={item.id}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center pt-20">
                  <div className="" key={index}>
                    <img src={item?.image} alt="Puffer Tote Bag" />
                  </div>

                  <div className="text-center">
                    <h2
                      className="font-bold mb-4"
                      style={{
                        fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                      }}
                    >
                      {item?.title}
                    </h2>
                    <p className="text-[18px] text-gray-600" dangerouslySetInnerHTML={{
                              __html: item?.description}}>
                    </p>
                  </div>
                </div>
                {/* <div className="m-5">{adComponent}</div> */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-12">
                  {item?.subProducts?.map((blog, index) => (
                    <div className="" key={index}>
                      <img src={blog?.image} alt={blog.title} />
                      <p
                        className="text-center mt-1"
                        style={{
                          fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                        }}
                      >
                        {blog?.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default MillionaireShopping;

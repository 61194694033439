import React, { useEffect, useState, lazy, Suspense } from "react";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BackToTop from "../Components/backToTop";
import LazyLoad from "react-lazyload";
import decrypt from "../Components/Common/DecryptData";
import OutBrainAdScroll from "../Components/ads/OutBrainAdScroll";

const LongAd02 = lazy(() => import("../Components/ads/LongAd02"));

const Banner = lazy(() => import("../Components/HomeSubPage/Banner"));
const Club = lazy(() => import("../Components/HomeSubPage/Club"));
const BlogPost = lazy(() => import("../Components/HomeSubPage/Blog-Post"));
const TopEarner = lazy(() => import("../Components/Top-earner"));
const CelebrityEvents = lazy(() => import("./Celebrity-events"));
const MoreHeadline = lazy(() =>
  import("../Components/HomeSubPage/moreHeadline")
);

const FAQ = lazy(() => import("../Components/HomeSubPage/FAQ"));
const SubBlog = lazy(() => import("../Components/HomeSubPage/Sub-blog"));
const BetweenContent = lazy(() => import("../Components/ads/BetweenContent"));
const BelowFeaturedImages = lazy(() =>
  import("../Components/ads/BelowFeaturedImages")
);
const EndOfArticle = lazy(() => import("../Components/ads/EndOfArticle"));
const ParallaxCode = lazy(() => import("../Components/ads/ParallaxCode"));

const FinanceSteps = lazy(() =>
  import("../Components/HomeSubPage/FinanceSteps")
);

const shuffleArray = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const Home = ({ setBlogLoading }) => {
  const [sliceData, setSliceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const page = 0;
  const limit = 100;

  const reloadAds = () => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display("div-gpt-ad-1726468773337-0");
        window.googletag.display("div-gpt-ad-1726053586752-0");
      });
    }
    if (window.googletag && window.googletag.pubads) {
      try {
        window.googletag.pubads().refresh();
      } catch (error) {
        console.error("Failed to refresh ads:", error);
      }
    }
  };

  useEffect(() => {
    const allBlogData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`
        );

        setLoading(false);

        if (response && response.data) {
          const { iv, end } = response.data;

          const decryptedDataString = decrypt(iv, end);
          const decryptedData = JSON.parse(decryptedDataString);

          const shuffledData = shuffleArray([...decryptedData.data]);

          const sortedHeadlines = shuffledData.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          const firstThreeItems = sortedHeadlines.slice(0, 6);
          setSliceData(firstThreeItems);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };

    allBlogData();
    reloadAds();
  }, [limit, page]);

  return (
    <Suspense>
      <div className="min-h-[100vh]">
        <Banner />
        <div className="container mt-44 lg:flex mx-auto max-w-[1700px] gap-4 px-7">
          <div className="lg:w-[75%]">
            <LazyLoad height={200} offset={100}>
              <TopEarner />
            </LazyLoad>

            {/* <div className="my-5">
              <BetweenContent />
            </div> */}
            <LazyLoad height={200} offset={100}>
              <BlogPost
                blogData={sliceData}
                loading={loading}
                setBlogLoading={setBlogLoading}
              />
            </LazyLoad>
          </div>

          <div className="lg:w-[25%] h-full lg:block hidden">
            {/* <BelowFeaturedImages /> */}
            <div className="border border-[#cacaca] my-10">
              <LazyLoad height={200} offset={100}>
                <SubBlog
                  url={`${Base_Url}api/get-blog?limit=100&page=1`}
                  link="blog"
                  title="Recent Blog"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
        {/* <ParallaxCode /> */}
        <OutBrainAdScroll />
        <LazyLoad height={200} offset={100}>
          <MoreHeadline />
        </LazyLoad>
        {/* <div className="my-5">
          <BetweenContent />
        </div> */}

        <div className="container mx-auto max-w-[1700px] px-7">
          <LazyLoad height={200} offset={100}>
            <CelebrityEvents />
          </LazyLoad>
        </div>
        {/* <EndOfArticle /> */}
        <LazyLoad height={200} offset={100}>
          <Club />
        </LazyLoad>
        {/* <LongAd02 /> */}
        {/* <BelowFeaturedImages /> */}

        <div className="container lg:flex mx-auto max-w-[1700px] gap-4 px-7">
          <div className="lg:w-[75%]">
            <LazyLoad height={200} offset={100}>
              <FAQ />
            </LazyLoad>
          </div>
          <div className="lg:w-[25%] my-14 lg:block hidden">
            <div className="shadow-2xl rounded-lg border border-[#cacaca] my-16">
              <LazyLoad height={200} offset={100}>
                <FinanceSteps />
              </LazyLoad>
            </div>
          </div>
        </div>

        <BackToTop />
      </div>
    </Suspense>
  );
};

export default Home;

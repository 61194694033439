import React from "react";
import OutBrainAdslot1 from "../ads/OutBrainAdslot1";
// import LongAd05 from "../Ads/LongAd05";
// import LongAd06 from "../Ads/LongAd06";
// import LongAd07 from "../Ads/LongAd07";
// import LongAd08 from "../Ads/LongAd08";

const adsArray = [
  <OutBrainAdslot1 />,
  // <LongAd05 key="ad-1" />,
  // <LongAd06 key="ad-2" />,
  // <LongAd07 key="ad-3" />,
  // <LongAd08 key="ad-4" />,
];

const BlogWithParallax = ({ description }) => {
  const BetweenContentAd = "{{BETWEEN_CONTENT_AD}}";
  const parts = description?.split(BetweenContentAd);

  return (
    <div className="blog-content p-4">
      {parts?.map((part, index) => (
        <div className="blog-part" key={index}>
          <div dangerouslySetInnerHTML={{ __html: part }} />

          {index < parts.length - 1 && (
            <div className="ad-slot py-5">
              {/* Display ad */}
              {adsArray[index]}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BlogWithParallax;

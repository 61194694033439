import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BetweenContent from "./ads/BetweenContent";
import BackToTop from "./backToTop";
import { stepData } from "./data/data";
import EndOfArticle from "./ads/EndOfArticle";
import LeftSideAd from "./ads/LeftSideAd";
import RightSideAd from "./ads/RightSideAd";
import OutBrainAdRightSide from "./ads/OutBrainAdRightSide";

const SuccessAllStep = () => {
  const location = useLocation();
  const stepName = location.search?.split("?");
  const stepsRecord = stepData?.find((x) => x?.link === stepName[1]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1740);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  return (
    <>
      {isDesktop && (
        <>
          {/* <LeftSideAd /> */}
          <OutBrainAdRightSide position={"fixed"} />
        </>
      )}
      <div className="container max-w-7xl mx-auto pt-[3rem] max-[768px]:pt-[1.5rem] px-5">
        <div className="flex justify-center">
          <h1 className="text-black md:text-[37px]  my-3 font-bold text-[30px] max-[1024px]:text-center">
            {stepsRecord?.title}
          </h1>
        </div>
        <div className="main-div py-3">
          {stepsRecord?.description?.map((x, index) => {
            return (
              <div className="md:text-[20px] text-[17px]">
                <p>{x}</p>
                {/* {index === 1 && (
                  <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                    <BetweenContent />
                  </div>
                )}
                {index === 4 && (
                  <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                    <EndOfArticle />
                  </div>
                )} */}
                <br />
                {x?.listing &&
                  x?.listing?.map((a) => {
                    return (
                      <ul className="list-disc">
                        <li className="">{a}</li>
                      </ul>
                    );
                  })}
              </div>
            );
          })}
        </div>

        <BackToTop />
      </div>
    </>
  );
};

export default SuccessAllStep;

import React, { useEffect, useMemo } from "react";
import { celebrityEvents } from "../Components/data/data";
import Card from "../Components/Common/Card";
import LongAd03 from "../Components/ads/LongAd03";

const CelebrityEvents = () => {
  useEffect(() => {
    const reloadAds = () => {
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(function () {
          window.googletag.display("div-gpt-ad-1721020427956-0");
        });
      }
      if (window.googletag && window.googletag.pubads) {
        try {
          window.googletag.pubads().refresh();
        } catch (error) {
          console.error("Failed to refresh ads:", error);
        }
      }
    };
    reloadAds();
  }, []);

  const eventCards = useMemo(
    () =>
      celebrityEvents?.map((data, index) => (
        <React.Fragment key={index}>
          <Card data={data} />

          {/* {index === 2 && window.location.pathname === "/" && (
            <div className="lg:col-span-3 sm:col-span-2 col-span-1 mt-4">
              <LongAd03 />
            </div>
          )} */}
        </React.Fragment>
      )),
    []
  );

  return (
    <div>
      {/* Heading */}
      <h3
        className="py-3 border-t border-b border-[#C7CACC] text-center mt-16 mb-12 font-semibold lg:text-[3rem] sm:text-[2rem]"
        style={{
          fontFamily: "Playfair Display",
          fontSize: "clamp(1.5rem, 4vw, 3rem)",
        }}
      >
        Iconic Celebrity Events
      </h3>

      {/* Events Grid */}
      <div className="mt-7">
        <div className="grid lg:grid-cols-3 min-[500px]:grid-cols-2 grid-cols-1 gap-6">
          {eventCards}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CelebrityEvents);

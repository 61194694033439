import React, { useEffect } from "react";

const OutBrainAdRightSide = ({ position }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.outbrain.com/outbrain.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="sideAd"
      style={{
        position: position ? position : "fixed",
        right: "0",
        // color: "#fff",
        paddingTop: "190px",
        top: "100px",
        marginRight: "30px",
        height: "100vh",
        width: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "#E9EAEC",
        // borderTop: "1px solid #ddd",
        padding: "10px 0",
        overflow: "auto",
      }}
    >
      <div
        className="OUTBRAIN"
        style={{ backgroundColor: "#E9EAEC" }}
        data-ob-contenturl="DROP_PERMALINK_HERE"
        data-widget-id="SB_1"
        data-ob-installation-key="DATAB1N0Q28EBEG3G5HMPDAG2"
      ></div>
    </div>
  );
};

export default OutBrainAdRightSide;
